.nav-header {
  position: sticky;
  top: 0;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  z-index: 50;
}

.nav-header > ul {
  display: flex;
  overflow-y: scroll;
}

.header-item {
  margin: 0 0.5rem;
  color: var(--text-color);
  white-space: nowrap;
}

.header-link {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
}

.active {
  padding-bottom: 3px;
  border-bottom: 2px solid var(--accent-color);
}
