.section-experience {
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - var(--header-height));
  background: linear-gradient(
    to left,
    var(--gradient-color),
    var(--primary-color)
  );
  scroll-margin-top: var(--header-height);
}

.experience-group {
  width: 80vw;
  margin: 1rem 0;
  display: flex;
  text-align: start;
}

.experience-group > svg {
  padding-top: 4px;
  fill: var(--text-color);
}

.divider {
  width: 100%;
  height: 0.1rem;
  margin: 4rem 0;
}

/* DESKTOP */

@media (width > 48em) {
  .experience-group {
    width: 60vw;
  }
}
