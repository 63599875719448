.section-my-work {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--header-height));
  background: linear-gradient(
    to left,
    var(--gradient-color),
    var(--primary-color)
  );
  scroll-margin-top: var(--header-height);
}

.work-slide {
  min-height: 10rem;
  padding: 2rem;
  border-radius: 0.4em;
  background: var(--background-color-dark);
  cursor: grab;
}

.work-slide:active {
  cursor: grabbing;
}

.work-slide > svg {
  width: 6rem;
}

.work-slide-header {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.project-ref {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.project-ref > svg {
  width: 1rem;
  height: 1rem;
}

.reebok-logo > svg {
  width: 5rem;
  height: 2rem;
}

.ami-logo > svg {
  width: 5rem;
  height: 3rem;
}

.harrods-logo > svg {
  width: 4rem;
  height: 2rem;
}

.browns-logo > svg {
  width: 6.5rem;
  height: 2rem;
}

.sg-logo > svg {
  width: 9rem;
  height: 2rem;
}
