.experience-accordion {
  width: 80vw;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: justify;
  border-radius: 0.3em;
  background: var(--background-color-dark);
  z-index: 10;
  cursor: pointer;
}

.experience-info {
  display: flex;
}

.experience-img {
  display: flex;
  align-items: center;
}

.experience-img > div {
  display: flex;
}

.experience-img img {
  width: 3rem;
  border-radius: 0.3rem;
}

.experience-header {
  margin-left: 1rem;
  flex-grow: 1;
  text-align: initial;
}

.experience-header > h3 {
  margin-bottom: 0.3rem;
}

.experience-header > h4 {
  margin-top: 0.2rem;
}

.experience-description {
  margin-top: 2rem;
}

.accordion-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordion-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in;
}

.show-more {
  max-height: 1000px;
}

.description-item {
  margin-bottom: 0.5rem;
}

.description-item::before {
  content: '•';
  padding-right: 0.5rem;
}

/* DESKTOP */

@media (width > 48em) {
  .experience-accordion {
    width: 60vw;
  }

  .experience-img img {
    width: 4rem;
  }
}
