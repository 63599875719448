.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 20px;
  height: 35px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 2px var(--text-color);
  border-radius: 25px;
}

.icon-scroll:before {
  content: '';
  width: 4px;
  height: 5px;
  background: var(--text-color);
  margin-left: -2px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(18px);
  }
}
