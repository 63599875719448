@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;700&display=swap');

:root {
  --background-color: #333;
  --text-color: #d8e1e3;
  --primary-color: #001b29;
  --accent-color: #bc6c29;
  --gradient-color: #003652;

  --background-color-dark: rgb(4, 4, 4, 0.2);
  --animation-color: rgba(228, 224, 224, 0.4);
  --header-height: 96px;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

body {
  font-family: 'Red Hat Text', sans-serif;
  font-size: 0.9rem;
  margin: 0;
  color: var(--text-color);
}

h2 {
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
}

h3 {
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
}

h4 {
  margin: 0;
  font-weight: 500;
  font-size: 0.7rem;
}

p {
  max-width: 52ch;
  line-height: 1.5;
  padding: 1em;
  color: var(--text-color);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--text-color);
}

.link-as-button {
  display: inline-block;
  padding: 1em 3.5em;
  opacity: 0.9;
  color: var(--text-color);
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0.3em;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
}

@media (width > 48em) {
  body {
    font-size: 1.1rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
}
