.socials-list {
  display: flex;
}

.social-item {
  margin-right: 1rem;
  cursor: pointer;
}

.social-item-link > svg {
  width: 1rem;
  height: 1rem;
}
