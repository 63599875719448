.section-about-me {
  position: relative;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to left,
    var(--gradient-color),
    var(--primary-color)
  );
}

.about-me {
  width: 80vw;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.3em;
  background: var(--background-color-dark);
  z-index: 10;
}

.details {
  display: flex;
  height: 5rem;
}

.my-photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.my-photo-container > img {
  width: 5rem;
  border-radius: 50%;
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1rem;
}

.info > h2 {
  text-align: start;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.info-location {
  display: flex;
  margin-bottom: 0.5rem;
}

.info-location > span {
  padding-left: 2px;
}

.socials-container-mobile {
  margin-top: 5px;
  margin-left: 5px;
}

.socials-container-desktop {
  display: none;
}

.description {
  display: flex;
  margin-top: 2.5rem;
  text-align: justify;
}

.description-2 {
  display: flex;
  margin-top: 1rem;
  text-align: justify;
}

.more-button {
  position: relative;
  z-index: 10;
  margin-top: 2rem;
}

/* DESKTOP */

@media (width > 48em) {
  .about-me {
    width: 60vw;
  }

  .info {
    margin-left: 2rem;
  }

  .info-location > span {
    padding: 5px;
  }

  .socials-container-mobile {
    display: none;
  }

  .socials-container-desktop {
    display: flex;
    align-items: center;
  }
}

/* ANIMATIONS */
.scale-in-center {
  -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
