.section-skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - var(--header-height));
  background: linear-gradient(
    to left,
    var(--gradient-color),
    var(--primary-color)
  );
  scroll-margin-top: var(--header-height);
}

.skills-table {
  width: 80vw;
  margin: 1rem 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.3em;
  background: var(--background-color-dark);
  z-index: 10;
}

.skills-list {
  column-count: 2;
  column-gap: 3rem;
}

.skill-name {
  display: flex;
  padding-top: 1.5rem;
  align-items: center;
}

.skill-name > span {
  margin-left: 0.5rem;
  padding-top: 5px;
}

.skill-name > svg {
  width: 1.5rem;
  height: 1.5rem;
}

.skillbar {
  height: 0.2rem;
  border: 1px solid var(--text-color);
  padding: 2px;
  margin-top: 1rem;
  border-radius: 1rem;
}

.skillbar > span {
  display: block;
  position: relative;
  height: 100%;
  border-radius: 1rem;
  background-color: var(--accent-color);
}

.skill-percentage-95 {
  width: 95%;
}
.skill-percentage-90 {
  width: 90%;
}
.skill-percentage-85 {
  width: 85%;
}
.skill-percentage-80 {
  width: 80%;
}
.skill-percentage-75 {
  width: 75%;
}
.skill-percentage-70 {
  width: 70%;
}
.skill-percentage-65 {
  width: 65%;
}
.skill-percentage-60 {
  width: 60%;
}

/* DESKTOP */

@media (width > 48em) {
  .skills-table {
    width: 60vw;
  }
}
